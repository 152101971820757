import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const List = makeShortcode("List");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "curated-material---jamming-on-value",
      "style": {
        "position": "relative"
      }
    }}>{`Curated Material - Jamming On Value`}<a parentName="h1" {...{
        "href": "#curated-material---jamming-on-value",
        "aria-label": "curated material   jamming on value permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <h2 {...{
      "id": "-web-3-game-developers-to-follow",
      "style": {
        "position": "relative"
      }
    }}>{`🙌 Web 3 Game Developers To Follow`}<a parentName="h2" {...{
        "href": "#-web-3-game-developers-to-follow",
        "aria-label": " web 3 game developers to follow permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <List mdxType="List">
      <Link to="https://austingriffith.com" mdxType="Link">
        <h4 {...{
          "id": "austin-griffith",
          "style": {
            "position": "relative"
          }
        }}>{`Austin Griffith`}<a parentName="h4" {...{
            "href": "#austin-griffith",
            "aria-label": "austin griffith permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
      </Link>
      <Link to="https://ronan.eth.link/" mdxType="Link">
        <h4 {...{
          "id": "ronan-sanford",
          "style": {
            "position": "relative"
          }
        }}>{`Ronan Sanford`}<a parentName="h4" {...{
            "href": "#ronan-sanford",
            "aria-label": "ronan sanford permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
      </Link>
    </List>
    <p>{`Please contribute more with a PR! 📝`}</p>
    <List mdxType="List">
      <Link to="https://bitcoinmagazine.com/articles/how-hackathon-birthed-cryptokitties-origin-story1" mdxType="Link">
        <h4 {...{
          "id": "cryptokitties-origin-story",
          "style": {
            "position": "relative"
          }
        }}>{`Cryptokitties Origin Story`}<a parentName="h4" {...{
            "href": "#cryptokitties-origin-story",
            "aria-label": "cryptokitties origin story permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`The story of how a hackathon helped create one of the biggest successes in the NFT space.`}</p>
      </Link>
      <Link to="https://github.com/ethereum/EIPs/blob/master/EIPS/eip-721.md" mdxType="Link">
        <h4 {...{
          "id": "ethereum-improvement-proposal-721",
          "style": {
            "position": "relative"
          }
        }}>{`Ethereum Improvement Proposal 721`}<a parentName="h4" {...{
            "href": "#ethereum-improvement-proposal-721",
            "aria-label": "ethereum improvement proposal 721 permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`The evolving document defining the NFT standard. Used by Cryptokitties and a multitude of other Web 3 games.`}</p>
      </Link>
      <Link to="https://www.wired.com/2011/07/mf-chainworld/" mdxType="Link">
        <h4 {...{
          "id": "chain-world",
          "style": {
            "position": "relative"
          }
        }}>{`Chain World`}<a parentName="h4" {...{
            "href": "#chain-world",
            "aria-label": "chain world permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`Something to read after watching `}<a parentName="p" {...{
            "href": "/learn/module-1/joyful-subversion"
          }}>{`May Li Khoe's Subverting the Status Quo`}</a>{`, an engrossing story on Jason Rohrer's subversive game design.`}</p>
      </Link>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      